<template>
  <input
    class="w-10 grid rounded place-items-center h-14 xs:w-12 xs:h-16 md:w-14 md:h-20 border-1 border-others-black transition-transform duration-1000 focus:border-1 focus:border-sanremo-primaryblue focus:scale-125 outline-none text-center font-lato"
    :class="[
      wrong ? 'bg-red-300 transition-colors shake' : '',
      success ? 'bg-green-300 transition-colors success' : '',
      !wrong && !success ? 'bg-white' : '',
    ]"
    type="number"
    @click="emit('select')"
    @input="updateInput"
    :value="modelWrapper"
    autocomplete="off"
    :disabled="disableInput"
  />
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
})

const wrong = ref(false)
const success = ref(false)
const disableInput = ref(false)

const emit = defineEmits(['update:modelValue', 'changeInput', 'select'])

const model = ref(props.modelValue)

const codeStatus = computed(() => {
  return props.status
})

watch(codeStatus, () => {
  if (codeStatus.value == 'INCOMPLETED') {
    wrong.value = false
  }
  if (codeStatus.value == 'WRONG') {
    wrong.value = true
    return
  }

  if (codeStatus.value == 'SUCCESS') {
    success.value = true
    disableInput.value = true
  }
})

const modelWrapper = computed({
  set: function (value) {
    model.value = value
  },
  get: function () {
    return props.modelValue
  },
})

const updateInput = (event) => {
  event.target.value = event.target.value.replace(/[^0-9]/g, '')
  modelWrapper.value = event.target.value
  emit('update:modelValue', model.value)
  emit('changeInput', event)
}
</script>

<style scoped>
.shake {
  text-align: center;
  animation: shakeInput 1s forwards;
}

@keyframes shakeInput {
  0% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0);
  }
}

.success {
  text-align: center;
  animation: successInput 1s forwards;
}

@keyframes successInput {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
